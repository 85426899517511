<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import { useSiteStore } from '~/stores/site.store';

const { $t } = useNuxtApp();
const auth = useAuthStore();
const site = useSiteStore();

const idVerificationStatus: globalThis.Ref<string> = ref('');
const welcomeOffer: globalThis.Ref<string> = ref();
const securityQuestion: globalThis.Ref<string> = ref();
const securityAnswer: globalThis.Ref<string> = ref();

onMounted(() => {
  //make call and set all vals
  idVerificationStatus.value = $t('Unsuccessful');
  // welcomeOffer.value = auth.getWelcomeOffer;

  // securityQuestion.value = securityQuestion.value ?? $t('not-selected');
  // securityAnswer.value = securityAnswer.value ?? $t('not-selected');
});

function verifyAccount() {
  site.deactivateModal();
  auth.setAccountModalPage('document-verification');
}
</script>

<template>
  <div class="flex flex-col flex-wrap justify-center align-content-start mx-3">
    <div class="flex justify-center mt-3 w-full mb-1">
      <InformationCircleIcon class="text-gold-500 w-16 h-16" />
    </div>
    <p class="text-base-priority font-bold mb-3">
      {{ $t('jpc-partially-complete-profile') }}
    </p>
    <p class="text-base-priority mb-3">
      <strong>{{ $t('id-verification') }}:&nbsp;</strong>
      <span class="text-gold-500">
        {{ $t(idVerificationStatus) }}
      </span>
    </p>
    <div v-if="auth.getWelcomeOffer">
      <p class="font-bold my-0 text-base-priority">
        {{ $t('jpc-welcome-offer-chosen') }}:
      </p>
      <p class="mt-1 mb-1 text-base-priority">
        {{ $t('jpc-welcome-offer-header') }}
      </p>
      <p class="mt-0 line-height-2 text-base-priority">
        {{ $t('jpc-welcome-offer-body') }}
      </p>
    </div>
    <!-- TODO: Feature flag on Welcome offer & security questions -->
    <!-- <p v-if="auth.getWelcomeOffer.qualifies">
      <strong>{{ $t('welcome-offer-chosen') }}:</strong>
      {{ $t(welcomeOffer) || $t('not-selected') }}
    </p>
    <p v-if="false">
      <strong>{{ $t('security-question') }}:</strong>
      {{ $t(securityQuestion) }}
    </p>
    <p v-if="false">
      <strong>{{ $t('security-answer') }}:</strong>
      {{ $t(securityAnswer) }}
    </p> -->
  </div>
  <div class="w-full dark:bg-dark-900 bg-light-200 flex justify-center">
    <Button
      element-name="verify-account"
      @click="verifyAccount()"
      class="w-full m-3 justify-center"
    >
      {{ $t('verify-account') }}
    </Button>
  </div>
</template>

<style lang="scss"></style>
